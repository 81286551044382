
import { each, map } from "lodash";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  Ref,
  ref,
  watch,
} from "vue";
import { asyncForEach } from "@hd2/common/src/utils";
import Tags from "@hd2/common/src/components/Tags.vue";
import { useStore } from "../store";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";
import { useRouter } from "vue-router";
import { AxiosStatic } from "axios";
import {
  CreateOrderData,
  CreateOrderDataPatient,
  OrderExt,
  PaymentType,
  RuleObjectExt,
  Symptom,
} from "../../types/index";
import { actions } from "../utils/const";
import { usePermissions } from "../composable/usePermissions";
import { Form } from "ant-design-vue";

const useForm = Form.useForm;

interface PatientModel {
  predefinedSymptoms: Set<string>;
  otherSymptoms: string;
}

interface PatientData {
  model: PatientModel;
  rules: Record<string, Array<RuleObjectExt>>;
}

export const OrderSymptomsComponent = defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    Tags,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;

    const appType = store.state.runtimeConfig.type;
    const { hasPermission } = usePermissions();

    const submitLoading: Ref<boolean> = ref(false);

    const patients: Ref<Array<PatientData>> = ref([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patientsForm: Ref<Array<any>> = ref([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const patientsFormTemplate: Ref<Array<any>> = ref([]);
    const valid: Ref<boolean> = ref(false);

    const predefinedSymptomsOptions: { [key: string]: Symptom } = reactive({
      "0": {
        name: t("PREDEFINED_SYMPTOMS.HEADACHES"),
      },
      "1": {
        name: t("PREDEFINED_SYMPTOMS.QATAR"),
      },
      "2": {
        name: t("PREDEFINED_SYMPTOMS.NAUSEA"),
      },
      "3": {
        name: t("PREDEFINED_SYMPTOMS.COUGH"),
      },
      "4": {
        name: t("PREDEFINED_SYMPTOMS.SORE_THROAT"),
      },
      "5": {
        name: t("PREDEFINED_SYMPTOMS.FEVER"),
      },
      "6": {
        name: t("PREDEFINED_SYMPTOMS.DYSPNEA"),
      },
      "7": {
        name: t("PREDEFINED_SYMPTOMS.SINUS_PAIN"),
      },
      "8": {
        name: t("PREDEFINED_SYMPTOMS.FATIGUE"),
      },
    });

    each(store.state.order.patients, (value, index) => {
      patients.value.push({
        model: {
          predefinedSymptoms: new Set(),
          otherSymptoms: value.otherSymptoms,
        },
        rules: {
          predefinedSymptoms: [
            {
              options: {
                index,
              },
              validator: (rule: RuleObjectExt) => {
                return new Promise((resolve, reject) => {
                  if (
                    !patients.value[rule.options?.index].model.otherSymptoms
                  ) {
                    if (
                      patients.value[rule.options?.index].model
                        .predefinedSymptoms.size === 0
                    ) {
                      reject(t("ORDER_SYMPTOMS.PATIENT.SYMPTOM_REQUIRED"));
                    }
                  }
                  resolve();
                });
              },
              trigger: "change",
            },
          ],
          otherSymptoms: [
            {
              options: {
                index,
              },
              validator: (rule: RuleObjectExt) => {
                return new Promise((resolve, reject) => {
                  if (
                    patients.value[rule.options?.index].model.predefinedSymptoms
                      .size === 0
                  ) {
                    if (
                      !patients.value[rule.options?.index].model.otherSymptoms
                    ) {
                      reject(t("ORDER_SYMPTOMS.PATIENT.SYMPTOM_REQUIRED"));
                    }
                  }
                  resolve();
                });
              },
              trigger: "change",
            },
          ],
        },
      });
    });

    each(patients.value, (patient) => {
      patientsForm.value.push(useForm(patient.model, patient.rules));
    });

    if (store.state.order.patients[0].predefinedSymptoms.length > 0) {
      each(store.state.order.patients, (value, index) => {
        patients.value[index].model.predefinedSymptoms = new Set(
          Object.keys(value.predefinedSymptoms)
        );
        patients.value[index].model.otherSymptoms = value.otherSymptoms;
      });
    }

    const refreshSlot = async (id: OrderExt["id"]) => {
      return await http.post("patient-portal/api/slot/extend", {
        id,
      });
    };

    const submit = async () => {
      submitLoading.value = true;

      const order: OrderExt = { ...store.state.order };
      order.patients = map(order.patients, (patient, index) => {
        return {
          ...patient,
          ...patients.value[index].model,
          predefinedSymptoms: map(
            Array.from(patients.value[index].model.predefinedSymptoms),
            (predefinedSymptomId) => {
              return predefinedSymptomsOptions[predefinedSymptomId];
            }
          ),
        };
      });
      store.commit("setOrder", order);

      if (hasPermission(actions.SHOW_PRICE)) {
        submitLoading.value = false;
        await router.push({
          name: "OrderPayment",
          params: { id: props.id },
        });
      } else {
        const resBody: CreateOrderData = {
          paymentType: "INTERNAL_BY_LINK" as PaymentType,
          email: store.state.order.email,
          discountVoucher: "",
          phoneNumber:
            store.state.order.phoneNumber.prefix +
            store.state.order.phoneNumber.number,
          slotReservationId: store.state.order.id,
          additionalPatients: map(store.state.order.patients, (patient) => {
            const symptoms = patient.otherSymptoms
              .replace(", ", ",")
              .split(",");

            const tmpPatient: CreateOrderDataPatient = {
              firstName: patient.firstName,
              lastName: patient.lastName,
              additionalPatientNotes: symptoms.join(", "),
            };

            if (patient.identificationDocument.type === "PESEL") {
              tmpPatient.pesel = patient.identificationDocument.number;
            } else {
              tmpPatient.identificationDocument =
                patient.identificationDocument;
              tmpPatient.birthday = patient.birthdate;
            }

            return tmpPatient;
          }),
          address: {
            city: store.state.order.address.city,
            streetNumber: store.state.order.address.streetNumber,
            flatNumber: store.state.order.address.flatNumber,
            postCode: store.state.order.address.postCode,
            streetName: store.state.order.address.streetName,
            additionalInfo: store.state.order.address.additionalInfo,
          },
        };

        try {
          const appointmentId = await http
            .post(`patient-portal/api/appointments`, resBody)
            .then((res) => res.data);
          await router.push({
            name: "AppointmentSuccess",
            params: { id: appointmentId },
          });
        } catch {
          notification.open({
            message: t("ERROR.4237"),
            class: "error",
          });
        } finally {
          submitLoading.value = false;
        }
      }
    };

    const onOtherSymptomsChange = (index: number) => {
      patientsFormTemplate.value[index].validate("predefinedSymptoms");
      patientsFormTemplate.value[index].validate("otherSymptoms");
    };
    const onPredefinedSymptomsChange = (index: number) => {
      patientsFormTemplate.value[index].validate("predefinedSymptoms");
      patientsFormTemplate.value[index].validate("otherSymptoms")[0];
    };

    const checkForm = () => {
      if (hasPermission(actions.CHECK_SYMPTOM_FORM)) {
        let validCount = 0;
        asyncForEach(patientsForm.value, async (form, index: number) => {
          try {
            await form.validate();
            validCount++;
          } catch {
            return Promise.resolve();
          } finally {
            if (index === patients.value.length - 1) {
              if (validCount === patients.value.length) {
                valid.value = true;
              } else {
                valid.value = false;
              }
            }
          }
        });
      } else {
        valid.value = true;
      }
    };

    checkForm();

    watch(
      patients,
      () => {
        checkForm();
      },
      { deep: true }
    );

    onMounted(() => {
      try {
        refreshSlot(props.id);
      } catch {
        store.commit("setOrder", {});
        notification.open({
          message: t("ERROR.4865"),
          class: "error",
        });
        router.push({ name: "NfzVisitType" });
      }
    });

    return {
      patients,
      t,
      hasPermission,
      actions,
      patientsFormTemplate,
      valid,
      predefinedSymptomsOptions,
      submit,
      onPredefinedSymptomsChange,
      onOtherSymptomsChange,
      translationType: computed(() =>
        ["WELBI"].includes(appType) ? appType : "GENERAL"
      ),
    };
  },
});
export default OrderSymptomsComponent;
